import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import Technologies from '../containers/sections/technologies';
import Contact from '../containers/sections/contact';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Image from '../components/image';


import rightChevron from '../assets/svg/right-chevron.svg';
import downChevron from '../assets/svg/down-chevron.svg';
import serviceIcon from '../assets/svg/service.svg';




class ServicesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

    }

    render() {

        return (
            <div className="services-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>

                            <Col lg="12" className="content">
                                <h1 dangerouslySetInnerHTML={{__html: 'Inovativna rješenja<br />za rast vašeg biznisa'.translate(this.props.lang)}}></h1>
                            </Col>
                            <button className="scroll-down"><Isvg src={downChevron} /></button>
                        </Row>
                    </Container>
                </div>

                <section className="services-section">
                    <Container>

                        {
                            this.props.services && this.props.services.map((item, idx) => {
                                return (
                                    <Row className="item">
                                        <Col lg="5" className="content">
                                            <Link lang={this.props.lang} to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}`}> <h3>{Object.translate(item, 'info.title', this.props.lang)}</h3></Link>
                                            <div dangerouslySetInnerHTML={{ __html: Object.translate(item, 'info.text', this.props.lang) }}></div>
                                            {/*<p className="categories">Web App, Mobile App, Web Portal</p>*/}
                                            <Link lang={this.props.lang} to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}`}><button className="button">{'Detaljnije'.translate(this.props.lang)} <Isvg src={rightChevron} /></button></Link>
                                        </Col>
                                        <Col lg={{ size: 6, offset: 1 }} className="image">
                                            <Link lang={this.props.lang} to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}`}> <Image src={Object.get(item, 'detail.image')} /></Link>
                                        </Col>
                                    </Row>



                                )
                            })
                        }


                    </Container>
                </section>

                <Technologies lang={this.props.lang}/>

                <Contact lang={this.props.lang}/>

            </div>
        );
    }
}

export default Page(ServicesPage);