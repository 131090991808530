import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import Technologies from '../containers/sections/technologies';
import Contact from '../containers/sections/contact';
import moment from 'moment';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import ReactPaginate from 'react-paginate';
import Image from '../components/image';


import downChevron from '../assets/svg/down-chevron.svg';


var striptags = require('striptags');


class BlogPage extends Component {
    constructor(props) {
        super(props);

        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);


        this.state = {
            page: 0,
            items: [],
            categories: [],
            tags: {},
            ...props.initialData
        };
    }


    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for(let i=0;i<this.props.loadData.length;i++){
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }




        this.updateStateFromSearch(this.get);


    }

    get() {
        fetch('https://api.novamedia.agency/blog', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                category: this.state.category,
                tag: this.state.tag,
                lang: this.props.lang
            })
        }).then(res => res.json()).then((result) => {

            this.setState({
                items: result.items,
                total: result.total
            })
        })

    }


    render() {

        return (
            <div className="blog-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>

                            <Col lg="12" className="content">
                                <h1 dangerouslySetInnerHTML={{__html: 'NOVA media <span>Blog</span>'.translate(this.props.lang) }}></h1>
                                <p dangerouslySetInnerHTML={{__html: 'Napredak tehnologije donosi izazove i mogućnosti za svako poslovanje.<br/>Smatramo da je dio našeg posla pružiti našim klijentima važne informacije i<br/> održavati ih ažurnim o onome što se događa u digitalnom svijetu.'.translate(this.props.lang)}}></p>
                            </Col>
                            <button className="scroll-down"><Isvg src={downChevron} /></button>
                        </Row>
                    </Container>
                </div>


                <section className="news-section">
                    <Container>
                        <Row>
                            <Col lg="12" className="filters">
                                <span>{'Filteri'.translate(this.props.lang)}</span>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret>
                                        {'Kategorije'.translate(this.props.lang)}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            this.state.categories && this.state.categories.map((item, idx) => {
                                                return (
                                                    <DropdownItem onClick={() => this.updateParam('category', Object.translate(item, 'alias', this.props.lang))}>{Object.translate(item, 'name', this.props.lang)}</DropdownItem>
                                                )
                                            })
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret>
                                        {'Tagovi'.translate(this.props.lang)}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            this.state.tags && this.state.tags[this.props.lang] && this.state.tags[this.props.lang].map((item, idx) => {
                                                return (
                                                    <DropdownItem onClick={() => this.updateParam('tag', item)} key={idx}>{item}</DropdownItem>

                                                )
                                            })
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </Col>
                            <Col lg="6" className="left">
                                {
                                    this.state.items.map((item, idx) => {
                                        if (idx % 2 == 0) {
                                            return (
                                                <div className="article">
                                                    <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}><Image src={item.image} /></Link>
                                                    <p className="category">{Object.translate(item, 'category.name', this.props.lang)}<span>•</span>{moment.unix(item.timestamp).format('DD. MMMM YYYY.')}</p>
                                                    <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{striptags(Object.translate(item, 'title', this.props.lang))}</h3></Link>
                                                    <p>{Object.translate(item, 'shortDescription', this.props.lang)}</p>
                                                    <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}>{'Čitaj detaljnije'.translate(this.props.lang)}</Link>
                                                </div>

                                            )
                                        }
                                    })
                                }

                            </Col>

                            <Col lg="6" className="right">
                                {
                                    this.state.items.map((item, idx) => {
                                        if (idx % 2 != 0) {
                                            return (
                                                <div className="article">
                                                    <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}><Image src={item.image} /></Link>
                                                    <p className="category">{Object.translate(item, 'category.name', this.props.lang)}<span>•</span>{moment.unix(item.timestamp).format('DD. MMMM YYYY.')}</p>
                                                    <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{striptags(Object.translate(item, 'title', this.props.lang))}</h3></Link>
                                                    <p>{Object.translate(item, 'shortDescription', this.props.lang)}</p>
                                                    <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}>{'Čitaj detaljnije'.translate(this.props.lang)}</Link>
                                                </div>

                                            )
                                        }
                                    })
                                }
                            </Col>



                        </Row>{this.state.total / 10 > 1 ?
                            <Row>
                                <Col lg="12">
                                    <ReactPaginate
                                        previousLabel={'Prev'.translate(this.props.lang)}
                                        nextLabel={'Next'.translate(this.props.lang)}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.total / 10}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={(page) => { this.updateParam('page', page.selected) }}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        hrefBuilder={(page) => { return `?page=${page}` }}
                                    //forcePage={this.state.page}
                                    />

                                </Col>

                            </Row>

                            :

                            null
                        }

                    </Container>
                </section>



                <Contact lang={this.props.lang}/>

            </div>
        );
    }
}

export default Page(BlogPage);