import React, { Component } from 'react';
import Link from './link';
import Isvg from 'react-inlinesvg';

/*header*/

import logo from '../assets/svg/logo.svg';
import rightChevron from '../assets/svg/right-chevron.svg';
import upArrow from '../assets/svg/up.svg';
import mode from '../assets/svg/mode.svg';

import translateLinkToOrig from '../translateLinkToOrig';

import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

import LightModeSwitch from './lighModeSwitch';

class Header extends Component {
  constructor(props) {
    super(props);
    this.generateLangLink = this.generateLangLink.bind(this);

    this.state = {
      yScroll: 0
    };
  }

  generateLangLink(lang) {
    /*let broken = this.props[0].location.search.split('?').pop().split('&');
    let params = {};
    for (let i = 0; i < broken.length; i++) {
      if (broken[i].indexOf('=') !== -1) {
        params[broken[i].split('=')[0]] = broken[i].split('=')[1];
      }
    }

    params['lang'] = lang;

    let paramsArr = [];
    for (var key in params) {
      if (params.hasOwnProperty(key) && params[key]) {

        paramsArr.push(key + "=" + params[key]);
      }
    }




    let search = '?' + paramsArr.join('&');*/


    return `/?lang=${lang}`;

  }

  componentDidUpdate(prevProps) {
    if (prevProps.lightMode != this.props.lightMode) {
      setTimeout(() => {
        this.setState({
          showMobileLight: null
        })
      }, 800)
    }
  }


  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const yScroll =
      document.body.scrollTop || document.documentElement.scrollTop


    this.setState({
      yScroll: yScroll,
      scrollHeader: yScroll < this.state.yScroll,
    })
  }



  render() {

    return (
      <header className={this.state.yScroll > 100 && this.state.scrollHeader ? `scroll-header ${translateLinkToOrig(this.props[0].location.pathname) == '/' ? 'home-header' : ''}` : this.state.yScroll < 150 ? ` ${translateLinkToOrig(this.props[0].location.pathname) == '/' ? 'home-header' : ''}` : `hide-header  ${translateLinkToOrig(this.props[0].location.pathname) == '/' ? 'home-header' : ''}`}  >
        {(translateLinkToOrig(this.props[0].location.pathname) != '/' || (typeof window !== 'undefined' && translateLinkToOrig(this.props[0].location.pathname) == '/' && this.state.yScroll >= window.innerHeight)) || (typeof window !== 'undefined' && window.innerWidth < 1023) ?

          <LightModeSwitch lightMode={this.props.lightMode} onChange={this.props.setLightMode} className={this.state.showMobileLight && window.innerWidth < 1023 ? 'animate-light-switch' : null} />
          :
          null
        }
        <Link lang={this.props.lang} to='/'><Isvg src={logo} className="logo" /></Link>
        <ul className="navigation">
          <li className={ translateLinkToOrig(this.props[0].location.pathname) == '/' ? 'active' : ''} ><Link lang={this.props.lang} to='/'>{'Početna'.translate(this.props.lang)}</Link></li>
          <li className={translateLinkToOrig(this.props[0].location.pathname).indexOf('/usluge') !== -1 ? 'active' : ''}><Link lang={this.props.lang} to='/usluge'>{'Usluge'.translate(this.props.lang)}</Link></li>
          <li className={translateLinkToOrig(this.props[0].location.pathname).indexOf('/reference') !== -1 ? 'active' : ''}><Link lang={this.props.lang} to='/reference'>{'Reference'.translate(this.props.lang)}</Link></li>
          <li className={translateLinkToOrig(this.props[0].location.pathname) == '/o-nama' ? 'active' : ''}><Link lang={this.props.lang} to='/o-nama'>{'O nama'.translate(this.props.lang)}</Link></li>
          <li className={translateLinkToOrig(this.props[0].location.pathname).indexOf('/blog') !== -1 ? 'active' : ''}><Link lang={this.props.lang} to='/blog'>{'Blog'.translate(this.props.lang)}</Link></li>
          <li>
            <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })}>
              <DropdownToggle caret>
                {this.props.lang}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem><Link to={ "/" } lang='ba'>BA</Link></DropdownItem>
                <DropdownItem><Link to={ "/" } lang='sr'>SR</Link></DropdownItem>
                <DropdownItem><Link to={ "/" } lang='en'>EN</Link></DropdownItem>
                <DropdownItem><Link to={ "/" } lang='de'>DE</Link></DropdownItem>

              </DropdownMenu>
            </Dropdown>

          </li>

          <li>
            <Link lang={this.props.lang} to='/kontakt'>
              <button className="button">{'Kontakt'.translate(this.props.lang)} <Isvg src={rightChevron} /></button>
            </Link>
          </li>


        </ul>

        <div className={this.state.mobileNavigation ? "mobile-controls mobile-controls-navigation" : "mobile-controls"}>

          <div className="mobile-light-mode">
            <button onClick={() => this.setState({ showMobileLight: !this.state.showMobileLight })}>
              <Isvg src={mode} />
            </button>
          </div>


          <div className={this.state.mobileNavigation ? 'hamburger hamburger-animation' : 'hamburger'}>
            <button onClick={() => this.setState({ mobileNavigation: !this.state.mobileNavigation })}>
              <div></div>
              <div></div>
              <div></div>
            </button>
          </div>
        </div>

        <div className={this.state.mobileNavigation ? 'mobile-navigation mobile-navigation-open' : 'mobile-navigation'}>

          <ul>
            <li><Link lang={this.props.lang} to='/'>{'Početna'.translate(this.props.lang)}</Link></li>
            <li><Link lang={this.props.lang} to='/usluge'>{'Usluge'.translate(this.props.lang)}</Link></li>
            <li><Link lang={this.props.lang} to='/reference'>{'Reference'.translate(this.props.lang)}</Link></li>
            <li><Link lang={this.props.lang} to='/o-nama'>{'O nama'.translate(this.props.lang)}</Link></li>
            <li><Link lang={this.props.lang} to='/blog'>{'Blog'.translate(this.props.lang)}</Link></li>


            <li>
              <Link lang={this.props.lang} to='/kontakt'>
                <button className="button">{'Kontakt'.translate(this.props.lang)} <Isvg src={rightChevron} /></button>
              </Link>
            </li>
            <li>
              <Dropdown isOpen={this.state.dropdownOpen1} toggle={() => this.setState({ dropdownOpen1: !this.state.dropdownOpen1 })}>
                <DropdownToggle caret>
                  {this.props.lang}
                </DropdownToggle>
                <DropdownMenu>
                <DropdownItem><Link to={ "/" } lang='ba'>BA</Link></DropdownItem>
                <DropdownItem><Link to={ "/" } lang='sr'>SR</Link></DropdownItem>
                <DropdownItem><Link to={ "/" } lang='en'>EN</Link></DropdownItem>
                <DropdownItem><Link to={ "/" } lang='de'>DE</Link></DropdownItem>

                </DropdownMenu>
              </Dropdown>

            </li>

          </ul>

        </div>
        {typeof window !== 'undefined' && this.state.yScroll >= window.innerHeight ?
          <button className="scroll-up" onClick={() => {
            if (typeof window !== 'undefined') {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }
          }}>
            <Isvg src={upArrow} />
            <p>Top</p>
          </button>
          :
          null
        }


      </header>
    );
  }
}

export default Header;