import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

class LightModeSwitch extends Component {
    constructor(props) {
        super(props);
        this.select = this.select.bind(this);

        this.state = {
            active: 0
        };
    }

    select(val){
        /*this.setState({
            active: val
        })*/

        this.props.onChange(val);
    }




    render() {

        return (
            <div className={`light-mode-switch ${this.props.className}`}>
                <div className="overlay" style={{left: `${(2+70*this.props.lightMode)}px`}} ></div>
                <button className={this.props.lightMode === 0 ? 'active' : null} onClick={() => this.select(0)}>Normal</button>
                <button className={this.props.lightMode === 1 ? 'active' : null} onClick={() => this.select(1)}>Dark</button>
                <button className={this.props.lightMode === 2 ? 'active' : null} onClick={() => this.select(2)}>Light</button>
            </div>
        );
    }
}

export default LightModeSwitch;