import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import Technologies from '../containers/sections/technologies';
import Contact from '../containers/sections/contact';
import Services from '../containers/sections/services';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import Image from '../components/image';

import rightChevron from '../assets/svg/right-chevron.svg';
import downChevron from '../assets/svg/down-chevron.svg';

var striptags = require('striptags');

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for(let i=0;i<this.props.loadData.length;i++){
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }





    }


    render() {

        return (
            <div className="home-wrap">
                <div className="into-wrap">
                    <Image src={Object.get(this.state.data, 'section[0].image')} />
                    <div className="content">
                        <h1 dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[0].title', this.props.lang) }}></h1>
                        <Link disableTranslate lang={this.props.lang} to={Object.translate(this.state.data, 'section[0].buttonLink', this.props.lang)}>
                            <button className="button">{Object.translate(this.state.data, 'section[0].buttonText', this.props.lang)} <Isvg src={rightChevron} /></button>
                        </Link>
                    </div>
                    <button className="scroll-down"><Isvg src={downChevron} /></button>
                </div>
                {/*
                <section className="partners-section">
                    <Container>
                        <Row>
                            <Col lg="3">
                                <Link lang={this.props.lang} to='/'>
                                    <Isvg src={partnerIcon} />
                                </Link>
                            </Col>
                            <Col lg="3">
                                <Link lang={this.props.lang} to='/'>
                                    <Isvg src={partnerIcon} />
                                </Link>
                            </Col>
                            <Col lg="3">
                                <Link lang={this.props.lang} to='/'>
                                    <Isvg src={partnerIcon} />
                                </Link>
                            </Col>
                            <Col lg="3">
                                <Link lang={this.props.lang} to='/'>
                                    <Isvg src={partnerIcon} />
                                </Link>
                            </Col>

                        </Row>
                    </Container>
                </section>
                */}

                <Services lang={this.props.lang} services={this.props.services} />
                
                
                <section className="projects-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <div className="section-top">
                                    <h2>{'Izdvojeni projekti'.translate(this.props.lang)}</h2>
                                    <h6>{'Predstavljamo Vam neke od naših novijih radova.'.translate(this.props.lang)}</h6>
                                    <Link lang={this.props.lang} to='/reference'><button className="button">{'Pogledajte sve projekte'.translate(this.props.lang)} <Isvg src={rightChevron} /></button></Link>
                                </div>

                                {
                                    this.state.promotedProjects.map((item, idx) => {
                                        if (idx % 2 == 0) {
                                            return (
                                                <div className="article">
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><Image src={Object.get(item, 'info.image')} /></Link>
                                                    <p className="category">{Object.translate(item, 'category.name', this.props.lang)}</p>
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{Object.translate(item, 'info.title', this.props.lang)}</h3></Link>
                                                    <p>{Object.translate(item, 'info.text', this.props.lang)}</p>
                                                </div>


                                            )
                                        }
                                    })
                                }



                            </Col>

                            <Col lg="6">
                                {
                                    this.state.promotedProjects.map((item, idx) => {
                                        if (idx % 2 != 0) {
                                            return (
                                                <div className="article">
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><Image src={Object.get(item, 'info.image')} /></Link>
                                                    <p className="category">{Object.translate(item, 'category.name', this.props.lang)}</p>
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{Object.translate(item, 'info.title', this.props.lang)}</h3></Link>
                                                    <p>{Object.translate(item, 'info.text', this.props.lang)}</p>
                                                </div>


                                            )
                                        }
                                    })
                                }

                            </Col>



                        </Row>
                    </Container>
                </section>


                <section className="promo-video-section">
                    <div className="overlay"></div>
                    <Container>
                        <Row>
                            <Col lg="5">
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[1].text', this.props.lang) }}></div>
                                <h6>{Object.translate(this.state.data, 'section[1].title', this.props.lang)}</h6>
                            </Col>
                            <Col lg={{ size: 6, offset: 1 }}>
                                <Image src={Object.get(this.state.data, 'section[1].image', this.props.lang)} />
                            </Col>
                        </Row>
                    </Container>

                </section>


                <section className="about-section">
                    <Container>
                        <Row>
                            <Col lg="6" className="left">
                                <h3 dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[2].title', this.props.lang) }}></h3>
                                <Image src={Object.get(this.state.data, 'section[2].image', this.props.lang)} />
                            </Col>
                            <Col lg="6" className="right">
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[2].text[0]', this.props.lang) }}></div>
                                <h6>{Object.translate(this.state.data, 'section[2].textBold', this.props.lang)}</h6>
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[2].text[1]', this.props.lang) }}></div>
                                <Link lang={this.props.lang} disableTranslate to={Object.translate(this.state.data, 'section[2].buttonLink', this.props.lang)}>{Object.translate(this.state.data, 'section[2].buttonText', this.props.lang)}</Link>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Technologies lang={this.props.lang}/>

                <section className="blog-section">
                    <Container>
                        <Row>
                            <Col lg="5">
                                <h2 dangerouslySetInnerHTML={{__html: 'Čitajte naš<br /><span>blog</span>'.translate(this.props.lang)}}></h2>
                                <Link lang={this.props.lang} to='/blog'><button className="button">{'Pogledaj kompletan blog'.translate(this.props.lang)} <Isvg src={rightChevron} /></button></Link>
                            </Col>

                            <Col lg="7" className="articles">
                                {
                                    this.props.latestNews && this.props.latestNews.map((item, idx) => {
                                        return (

                                            <article>
                                                <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}> <Image src={item.image} /></Link>
                                                <div>
                                                    <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{striptags(Object.translate(item, 'title', this.props.lang))}</h3></Link>
                                                    <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}>{'Čitaj detaljnije'.translate(this.props.lang)}</Link>
                                                </div>
                                            </article>
                                        )
                                    })
                                }

                            </Col>
                        </Row>
                    </Container>
                </section>

                <Contact lang={this.props.lang}/>

            </div>
        );
    }
}

export default Page(HomePage);