import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';



import technologyIcon1 from '../../assets/svg/technologies/react.svg';
import technologyIcon2 from '../../assets/svg/technologies/node.svg';
import technologyIcon3 from '../../assets/svg/technologies/mongodb.svg';
import technologyIcon4 from '../../assets/svg/technologies/js.svg';
import technologyIcon5 from '../../assets/svg/technologies/socketio.svg';
import technologyIcon6 from '../../assets/svg/technologies/html5.svg';
import technologyIcon7 from '../../assets/svg/technologies/jquery.svg';
import technologyIcon8 from '../../assets/svg/technologies/mysql.svg';
import technologyIcon9 from '../../assets/svg/technologies/sass.svg';
import technologyIcon10 from '../../assets/svg/technologies/php.svg';
import technologyIcon11 from '../../assets/svg/technologies/python.svg';
import technologyIcon12 from '../../assets/svg/technologies/vuejs.svg';
import technologyIcon13 from '../../assets/svg/technologies/angular.svg';
import technologyIcon14 from '../../assets/svg/technologies/java.svg';
import technologyIcon15 from '../../assets/svg/technologies/android.svg';
import technologyIcon16 from '../../assets/svg/technologies/apple.svg';
import technologyIcon17 from '../../assets/svg/technologies/wordpress.svg';
import technologyIcon18 from '../../assets/svg/technologies/c.svg';
import technologyIcon19 from '../../assets/svg/technologies/solr.svg';
import technologyIcon20 from '../../assets/svg/technologies/bootstrap.svg';
import technologyIcon21 from '../../assets/svg/technologies/redis.svg';
import technologyIcon22 from '../../assets/svg/technologies/github.svg';
import technologyIcon23 from '../../assets/svg/technologies/selenium.svg';
import technologyIcon24 from '../../assets/svg/technologies/adobe.svg';


class Technologies extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        return (
      
                <section className="technologies-section">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>{'Alati i tehnologije'.translate(this.props.lang)}</h2>
                                <h6 dangerouslySetInnerHTML={{__html: 'Naš multidisciplinarni tim pruža vam usluge koristeći<br /> široku mrežu alata i tehnologija.'.translate(this.props.lang)} }></h6>
                            </Col>

                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon1} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon2} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon3} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon4} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon5} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon6} />
                            </Col>

                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon7} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon8} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon9} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon10} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon11} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon12} />
                            </Col>

                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon13} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon14} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon15} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon16} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon17} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon18} />
                            </Col>
                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon19} />
                            </Col>

                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon20} />
                            </Col>

                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon21} />
                            </Col>

                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon22} />
                            </Col>

                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon23} />
                            </Col>

                            <Col lg="2" xs="6" sm="4" className="technology-item">
                                <Isvg src={technologyIcon24} />
                            </Col>


                        </Row>
                    </Container>
                </section>

               
        );
    }
}

export default Technologies;