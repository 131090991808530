import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import Technologies from '../containers/sections/technologies';
import Contact from '../containers/sections/contact';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import Image from '../components/image';

import rightChevron from '../assets/svg/right-chevron.svg';
import downChevron from '../assets/svg/down-chevron.svg';




class ProjectsPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            categories: [],
            projects: [],
            page: 0,
            total: 0,
            ...props.initialData
        };
    }

    generateNextPageLink() {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params['page'] = parseInt(this.state.page) + 1;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }




        let search = '?' + paramsArr.join('&');
        return search;

    }

    updateStateFromSearch(callback, init) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        if (init) {
            params.page = 0;
        }

        this.setState({
            category: null,
            tag: null,
            page: 0,
            items: []
        }, () => {
            this.setState(params, callback);

        })

        if (init) {
            this.updateParam('page', 0);
        }

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log(prevProps[0].location)
        console.log(prevProps[0].location.search, this.props[0].location.search)
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get(prevState.category == this.state.category && prevState.page != this.state.page)
            });
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for(let i=0;i<this.props.loadData.length;i++){
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }



        this.updateStateFromSearch(this.get, true);



    }

    get(merge = false) {
        fetch('https://api.novamedia.agency/projects', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                category: this.state.category,
                lang: this.props.lang,
                page: this.state.page
            })
        }).then(res => res.json()).then((result) => {

            if (merge) {
                result.items = this.state.projects.concat(result.items);
            }

            this.setState({
                projects: result.items,
                total: result.total
            })
        })


    }


    render() {

        return (
            <div className="projects-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>

                            <Col lg="12" className="content">
                                <h1 dangerouslySetInnerHTML={{__html: 'Naši <span>radovi</span>'.translate(this.props.lang) }}></h1>
                                <p dangerouslySetInnerHTML={{__html: 'Bilo da se radi o maloj web stranici ili sofisticiranoj poslovnoj aplikaciji,<br/>posvećeni smo u svakom aspektu onoga što radimo. Dokaz tome su naše reference.'.translate(this.props.lang) }} ></p>
                            </Col>
                            <button className="scroll-down"><Isvg src={downChevron} /></button>
                        </Row>
                    </Container>
                </div>

                <section className="projects-section">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <ul className="categories">
                                    <li className={this.state.category == null ? 'active' : ''}><Link lang={this.props.lang} to='/reference'>Sve</Link></li>
                                    {
                                        this.state.categories.map((item, idx) => {
                                            return (
                                                <li className={this.state.category == Object.translate(item, 'alias', this.props.lang) ? 'active' : ''}><Link lang={this.props.lang} to={`?category=${Object.translate(item, 'alias', this.props.lang)}`}>{Object.translate(item, 'name', this.props.lang)}</Link></li>
                                            )
                                        })
                                    }

                                </ul>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        {
                            this.state.projects.map((item, idx) => {
                                return (
                                    <Row className="item">
                                        <Col lg="6" md="7" sm="12" className="content">
                                            <div className="category"><h6>{Object.translate(item, 'category.name', this.props.lang)}</h6> <div></div></div>
                                            <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{Object.translate(item, 'info.title', this.props.lang)}</h3></Link>
                                            <p>{Object.translate(item, 'info.text', this.props.lang)}</p>
                                            <div className="spacer"></div>
                                            <p className="technologies-title">{'Tehnologije'.translate(this.props.lang) }</p>
                                            <p className="technologies">{Object.translate(item, 'technologies', this.props.lang)}</p>
                                            <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><button className="button">{'Pogledaj projekat'.translate(this.props.lang) }</button></Link>
                                        </Col>
                                        <Col lg="6" md="5" sm="12" className="image">
                                            <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><Image src={Object.get(item, 'info.image')} /></Link>
                                        </Col>
                                    </Row>

                                )
                            })
                        }


                    </Container>
                    <Container>
                        <Row>
                            <Col lg="12" className="load-more">
                                {parseInt(this.state.page) + 1 < this.state.total / 10 ?

                                    <Link lang={this.props.lang} to={this.generateNextPageLink()}><button className="button">{'Učitaj još projekata'.translate(this.props.lang) } <Isvg src={rightChevron} /></button></Link>
                                    :
                                    null
                                }

                            </Col>
                        </Row>
                    </Container>
                </section>


                <Contact lang={this.props.lang}/>

            </div>
        );
    }
}

export default Page(ProjectsPage);