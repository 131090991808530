import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Textarea from './fields/textarea';
import rightChevron from '../../assets/svg/right-chevron.svg';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    meta: { touched, error },
    type
}) => (

        <Text
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            type={type}
            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }



    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(pristine, submitting);

        return (
            <form onSubmit={handleSubmit}>
                <Field
                    name="name"
                    component={renderTextField}
                    placeholder={"Vaše ime".translate(this.props.lang)}
                    validate={[required]}
                ></Field>

                <Field
                    name="email"
                    type="email"
                    component={renderTextField}
                    placeholder={"Vaš E-mail".translate(this.props.lang)}
                    validate={[required]}

                ></Field>
                <Field
                    name="subject"
                    component={renderTextField}
                    placeholder={"Naslov".translate(this.props.lang)}
                    validate={[required]}

                ></Field>

                <Field
                    name="message"
                    component={renderTextareaField}
                    placeholder={"Vaša poruka".translate(this.props.lang)}
                    validate={[required]}

                ></Field>
                {this.props.formLoading ?
                    null
                    :
                    <button className="button">{'Pošalji poruku'.translate(this.props.lang)} <Isvg src={rightChevron} /></button>
                }
            </form>
        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
