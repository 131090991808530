export const langs = {
    "ba":{
        "Usluge":"Usluge",
        "Lokacija":"Lokacija",
        "Instagram feed":"Instagram feed",
        "Blog":"Blog",
        "Uslovi korištenja":"Uslovi korištenja",
        "Politika privatnosti":"Politika privatnosti",
        "Impressum":"Impressum",
        "Kontaktirajte nas":"Kontaktirajte nas",
        "https://www.facebook.com/www.novamedia.agency":"https://www.facebook.com/novamediaBIH",
        "https://www.instagram.com/novamedia.agency/":"https://www.instagram.com/novamedia.agency/",
        "https://www.linkedin.com/company/novamediabn/":"https://www.linkedin.com/company/novamediabn/",
        "https://twitter.com/novamedia01":"https://twitter.com/novamedia01",
        "Početna":"Početna",
        "Reference":"Reference",
        "O nama":"O nama",
        "Kontakt":"Kontakt",
        "Imate projekat o kojem<br/>želite da razgovarate?":"Imate projekat o kojem<br/>želite da razgovarate?",
        "ili":"ili",
        "Naše usluge":"Naše usluge",
        "Saznaj više":"Saznaj više",
        "Alati i tehnologije":"Alati i tehnologije",
        "Naš multidisciplinarni tim pruža vam usluge koristeći<br /> široku mrežu alata i tehnologija.":"Naš multidisciplinarni tim pruža vam usluge koristeći<br /> široku mrežu alata i tehnologija.",
        "Izdvojeni projekti":"Izdvojeni projekti",
        "Predstavljamo Vam neke od naših novijih radova.":"Predstavljamo Vam neke od naših novijih radova.",
        "Pogledajte sve projekte":"Pogledajte sve projekte",
        "Čitajte naš<br /><span>blog</span>":"Čitajte naš<br /><span>blog</span>",
        "Pogledaj kompletan blog":"Pogledaj kompletan blog",
        "Čitaj detaljnije":"Čitaj detaljnije",
        "Inovativna rješenja<br />za rast vašeg biznisa":"Inovativna rješenja<br />za rast vašeg biznisa",
        "Detaljnije":"Detaljnije",
        "Naši <span>radovi</span>":"Naši <span>radovi</span>",
        "Bilo da se radi o maloj web stranici ili sofisticiranoj poslovnoj aplikaciji,<br/>posvećeni smo u svakom aspektu onoga što radimo. Dokaz tome su naše reference.":"Bilo da se radi o maloj web stranici ili sofisticiranoj poslovnoj aplikaciji,<br/>posvećeni smo u svakom aspektu onoga što radimo. Dokaz tome su naše reference.",
        "NOVA media <span>Blog</span>":"NOVA media <span>Blog</span>",
        "Napredak tehnologije donosi izazove i mogućnosti za svako poslovanje.<br/>Smatramo da je dio našeg posla pružiti našim klijentima važne informacije i<br/> održavati ih ažurnim o onome što se događa u digitalnom svijetu.":"Napredak tehnologije donosi izazove i mogućnosti za svako poslovanje.<br/>Smatramo da je dio našeg posla pružiti našim klijentima važne informacije i<br/> održavati ih ažurnim o onome što se događa u digitalnom svijetu.",
        "Filteri":"Filteri",
        "Kategorije":"Kategorije",
        "Tagovi":"Tagovi",
        "Ispunite donji obrazac i uskoro ćemo biti u kontaktu.":"Ispunite donji obrazac i uskoro ćemo biti u kontaktu.",
        "Adresa":"Adresa",
        "Imate projekat o kojem želite da<br />razgovarate? Kontaktirajte nas.":"Imate projekat o kojem želite da<br />razgovarate? Kontaktirajte nas.",
        "Pozovite nas":"Pozovite nas",
        "Vaše ime":"Vaše ime",
        "Vaš E-mail":"Vaš E-mail",
        "Naslov":"Naslov",
        "Vaša poruka":"Vaša poruka",
        "Pošalji poruku":"Pošalji poruku",
        "Tehnologije":"Tehnologije",
        "Pogledaj projekat":"Pogledaj projekat",
        "Učitaj još projekata":"Učitaj još projekata",
        "O projektu":"O projektu",
        "Vaša poruka je poslata, očekujte odgovor ubrzo.":"Vaša poruka je poslata, očekujte odgovor ubrzo."
    },
    "sr":{
       "Usluge":"Usluge",
       "Lokacija":"Lokacija",
       "Instagram feed":"Instagram feed",
       "Blog":"Blog",
       "Uslovi korištenja":"Uslovi korištenja",
       "Politika privatnosti":"Politika privatnosti",
       "Impressum":"Impressum",
       "Kontaktirajte nas":"Kontaktirajte nas",
       "https://www.facebook.com/www.novamedia.agency":"https://www.facebook.com/novamediaSrbija",
       "https://www.instagram.com/novamedia.agency/":"https://www.instagram.com/novamedia.agency/",
       "https://www.linkedin.com/company/novamediabn/":"https://www.linkedin.com/company/novamediabn/",
       "https://twitter.com/novamedia01":"https://twitter.com/novamedia01",
       "Početna":"Početna",
       "Reference":"Reference",
       "O nama":"O nama",
       "Kontakt":"Kontakt",
       "Imate projekat o kojem<br/>želite da razgovarate?":"Imate projekat o kojem<br/>želite da razgovarate?",
       "ili":"ili",
       "Naše usluge":"Naše usluge",
       "Saznaj više":"Saznaj više",
       "Alati i tehnologije":"Alati i tehnologije",
       "Naš multidisciplinarni tim pruža vam usluge koristeći<br /> široku mrežu alata i tehnologija.":"Naš multidisciplinarni tim pruža vam usluge koristeći<br /> široku mrežu alata i tehnologija.",
       "Izdvojeni projekti":"Izdvojeni projekti",
       "Predstavljamo Vam neke od naših novijih radova.":"Predstavljamo Vam neke od naših novijih radova.",
       "Pogledajte sve projekte":"Pogledajte sve projekte",
       "Čitajte naš<br /><span>blog</span>":"Čitajte naš<br /><span>blog</span>",
       "Pogledaj kompletan blog":"Pogledaj kompletan blog",
       "Čitaj detaljnije":"Čitaj detaljnije",
       "Inovativna rješenja<br />za rast vašeg biznisa":"Inovativna rešenja<br />za rast vašeg biznisa",
       "Detaljnije":"Detaljnije",
       "Naši <span>radovi</span>":"Naši <span>radovi</span>",
       "Bilo da se radi o maloj web stranici ili sofisticiranoj poslovnoj aplikaciji,<br/>posvećeni smo u svakom aspektu onoga što radimo. Dokaz tome su naše reference.":"Bilo da se radi o maloj web stranici ili sofisticiranoj poslovnoj aplikaciji,<br/>posvećeni smo u svakom aspektu onoga što radimo. Dokaz tome su naše reference.",
       "NOVA media <span>Blog</span>":"NOVA media <span>Blog</span>",
       "Napredak tehnologije donosi izazove i mogućnosti za svako poslovanje.<br/>Smatramo da je dio našeg posla pružiti našim klijentima važne informacije i<br/> održavati ih ažurnim o onome što se događa u digitalnom svijetu.":"Napredak tehnologije donosi izazove i mogućnosti za svako poslovanje.<br/>Smatramo da je dio našeg posla pružiti našim klijentima važne informacije i<br/> održavati ih ažurnim o onome što se događa u digitalnom svijetu.",
       "Filteri":"Filteri",
       "Kategorije":"Kategorije",
       "Tagovi":"Tagovi",
       "Ispunite donji obrazac i uskoro ćemo biti u kontaktu.":"Ispunite donji obrazac i uskoro ćemo biti u kontaktu.",
       "Adresa":"Adresa",
       "Imate projekat o kojem želite da<br />razgovarate? Kontaktirajte nas.":"Imate projekat o kojem želite da<br />razgovarate? Kontaktirajte nas.",
       "Pozovite nas":"Pozovite nas",
       "Vaše ime":"Vaše ime",
       "Vaš E-mail":"Vaš E-mail",
       "Naslov":"Naslov",
       "Vaša poruka":"Vaša poruka",
       "Pošalji poruku":"Pošalji poruku",
       "Tehnologije":"Tehnologije",
       "Pogledaj projekat":"Pogledaj projekat",
       "Učitaj još projekata":"Učitaj još projekata",
       "O projektu":"O projektu",
       "Vaša poruka je poslata, očekujte odgovor ubrzo.":"Vaša poruka je poslata, očekujte odgovor ubrzo.",
       "Poslednji projekti": "Poslednji projekti"
    },
    "en":{
       "Usluge":"Services",
       "Lokacija":"Location",
       "Instagram feed":"Instagram feed",
       "Blog":"Blog",
       "Uslovi korištenja":"Terms and conditions",
       "Politika privatnosti":"Privacy policy",
       "Impressum":"Impressum",
       "Kontaktirajte nas":"Contact us",
       "https://www.facebook.com/www.novamedia.agency":"https://www.facebook.com/novamediaGlobal",
       "https://www.instagram.com/novamedia.agency/":"https://www.instagram.com/novamedia_agency/",
       "https://www.linkedin.com/company/novamediabn/":"https://www.linkedin.com/company/novamediabn/",
       "https://twitter.com/novamedia01":"https://twitter.com/novamedia01",
       "Početna":"Home",
       "Reference":"Portfolio",
       "O nama":"About us",
       "Kontakt":"Contact",
       "Imate projekat o kojem<br/>želite da razgovarate?":"Do you have a project<br/>you want to talk about?",
       "ili":"or",
       "Naše usluge":"Our Services",
       "Saznaj više":"Find out more",
       "Alati i tehnologije":"Tools and technologies",
       "Naš multidisciplinarni tim pruža vam usluge koristeći<br /> široku mrežu alata i tehnologija.":"Our multidisciplinary team provides you with a<br /> wide range of tools and technologies.",
       "Izdvojeni projekti":"Featured projects",
       "Predstavljamo Vam neke od naših novijih radova.":"We present you some of our recent work.",
       "Pogledajte sve projekte":"View all projects",
       "Čitajte naš<br /><span>blog</span>":"Read our<br /><span>blog</span>",
       "Pogledaj kompletan blog":"Check out the full blog",
       "Čitaj detaljnije":"Read more",
       "Inovativna rješenja<br />za rast vašeg biznisa":"Innovative solutions<br />to grow your business",
       "Detaljnije":"Find out more",
       "Naši <span>radovi</span>":"Our <span>projects</span>",
       "Bilo da se radi o maloj web stranici ili sofisticiranoj poslovnoj aplikaciji,<br/>posvećeni smo u svakom aspektu onoga što radimo. Dokaz tome su naše reference.":"Whether it is a small website or a sophisticated business application, we are dedicated in every aspect of what we do. Our references are proof of this.",
       "NOVA media <span>Blog</span>":"NOVA media <span>Blog</span>",
       "Napredak tehnologije donosi izazove i mogućnosti za svako poslovanje.<br/>Smatramo da je dio našeg posla pružiti našim klijentima važne informacije i<br/> održavati ih ažurnim o onome što se događa u digitalnom svijetu.":"The advancement of technology brings challenges and opportunities for every business.<br/>We believe that part of our job is to provide our customers with important information and<br/> keep them up to date on what's happening in the digital world.",
       "Filteri":"Filters",
       "Kategorije":"Categories",
       "Tagovi":"Tags",
       "Ispunite donji obrazac i uskoro ćemo biti u kontaktu.":"Fill out the form below and we will be in touch shortly.",
       "Adresa":"Address",
       "Imate projekat o kojem želite da<br />razgovarate? Kontaktirajte nas.":"Do you have a project<br/>you want to talk about?",
       "Pozovite nas":"Call us",
       "Vaše ime":"Your name",
       "Vaš E-mail":"Your E-mail",
       "Naslov":"Title",
       "Vaša poruka":"Your message",
       "Pošalji poruku":"Send message",
       "Tehnologije":"Technology",
       "Pogledaj projekat":"See project",
       "Učitaj još projekata":"Load more projects",
       "O projektu":"About project",
       "Vaša poruka je poslata, očekujte odgovor ubrzo.":"Your message has been sent, expect a reply soon.",
       "Poslednji projekti": "Latest projects"
    },
	  "de":{
       "Usluge":"Services",
       "Lokacija":"Ort",
       "Instagram feed":"Instagram feed",
       "Blog":"Blog",
       "Uslovi korištenja":"Arbeitsbedingungen",
       "Politika privatnosti":"Datenschutzerklärung",
       "Impressum":"Impressum",
       "Kontaktirajte nas":"Kontaktieren Sie uns",
       "https://www.facebook.com/www.novamedia.agency":"https://www.facebook.com/novamediaGlobal",
       "https://www.instagram.com/novamedia.agency/":"https://www.instagram.com/novamedia_agency/",
       "https://www.linkedin.com/company/novamediabn/":"https://www.linkedin.com/company/novamediabn/",
       "https://twitter.com/novamedia01":"https://twitter.com/novamedia01",
       "Početna":"Startseite",
       "Reference":"Portfolio",
       "O nama":"Über uns",
       "Kontakt":"Kontakt",
       "Imate projekat o kojem<br/>želite da razgovarate?":"Haben Sie ein Projekt,<br/>über das Sie sprechen möchten?",
       "ili":"oder",
       "Naše usluge":"Unsere Services",
       "Saznaj više":"Mehr erfahren",
       "Alati i tehnologije":"Tools und Technologien",
       "Naš multidisciplinarni tim pruža vam usluge koristeći<br /> široku mrežu alata i tehnologija.":"Unser multidisziplinäres Team bietet Ihnen eine<br /> breite Palette von Werkzeugen und Technologien.",
       "Izdvojeni projekti":"Ausgewählte Projekte",
       "Predstavljamo Vam neke od naših novijih radova.":"Wir präsentieren Ihnen einige unserer neuesten Projekte.",
       "Pogledajte sve projekte":"Alle Projekte anzeigen",
       "Čitajte naš<br /><span>blog</span>":"Lesen Sie unseren<br /><span>Blog</span>",
       "Pogledaj kompletan blog":"Schauen Sie sich den ganzen Blog an",
       "Čitaj detaljnije":"Mehr erfahren",
       "Inovativna rješenja<br />za rast vašeg biznisa":"Innovative Lösungen<br />für das Wachstum Ihres Unternehmens",
       "Detaljnije":"Mehr erfahren",
       "Naši <span>radovi</span>":"Unsere <span>Projekte</span>",
       "Bilo da se radi o maloj web stranici ili sofisticiranoj poslovnoj aplikaciji,<br/>posvećeni smo u svakom aspektu onoga što radimo. Dokaz tome su naše reference.":"Egal, ob es sich um eine kleine Website oder eine anspruchsvolle Geschäftsanwendung handelt,<br/>wir sind in jeder Hinsicht engagiert. Unseres Portfolio ist ein Beweis dafür.",
       "NOVA media <span>Blog</span>":"NOVA media <span>Blog</span>",
       "Napredak tehnologije donosi izazove i mogućnosti za svako poslovanje.<br/>Smatramo da je dio našeg posla pružiti našim klijentima važne informacije i<br/> održavati ih ažurnim o onome što se događa u digitalnom svijetu.":"The advancement of technology brings challenges and opportunities for every business.<br/>We believe that part of our job is to provide our customers with important information and<br/> keep them up to date on what's happening in the digital world.",
       "Filteri":"Filter",
       "Kategorije":"Kategorien",
       "Tagovi":"Stichworte",
       "Ispunite donji obrazac i uskoro ćemo biti u kontaktu.":"Füllen Sie das unten stehende Formular aus und wir werden uns in Kürze bei Ihnen melden.",
       "Adresa":"Adresse",
       "Imate projekat o kojem želite da<br />razgovarate? Kontaktirajte nas.":"Haben Sie ein Projekt, über das Sie<br/>sprechen möchten? Kontaktieren Sie uns.",
       "Pozovite nas":"Rufen Sie uns an",
       "Vaše ime":"Ihr Name",
       "Vaš E-mail":"Ihr E-Mail",
       "Naslov":"Betreff",
       "Vaša poruka":"Ihre Nachricht",
       "Pošalji poruku":"Eine Nachricht schicken",
       "Tehnologije":"Technologien",
       "Pogledaj projekat":"Zum Projekt",
       "Učitaj još projekata":"Mehr Projekte laden",
       "O projektu":"Über dem Projekt",
       "Vaša poruka je poslata, očekujte odgovor ubrzo.":"Ihre Nachricht wurde gesendet. Erwarten Sie bald eine Antwort.",
       "Poslednji projekti": "Neueste Projekte"
    }
 }