import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import Technologies from '../containers/sections/technologies';
import Contact from '../containers/sections/contact';
import Services from '../containers/sections/services';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import Image from '../components/image';

import downChevron from '../assets/svg/down-chevron.svg';




class AboutUsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') {window.scrollTo(0, 0);}


        for(let i=0;i<this.props.loadData.length;i++){
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


    }



    render() {

        return (
            <div className="about-us-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>

                            <Col lg="12" className="content">
                                <h1 dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'title', this.props.lang) }}></h1>
                            </Col>
                            <button className="scroll-down"><Isvg src={downChevron} /></button>
                        </Row>
                    </Container>
                </div>

                <section className="about-us-content">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2 dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[0].title', this.props.lang) }}>
                                </h2>
                                <p className="text-bold" dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[0].text[0]', this.props.lang) }}>

                                </p>
                            </Col>
                            <Col lg="6" dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[0].text[1]', this.props.lang) }}>
                            </Col>
                            <Col lg="6" dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[0].text[2]', this.props.lang) }}>
                            </Col>

                        </Row>
                    </Container>

                    <Image src={Object.get(this.state.data, 'section[0].image')} className="about-us-image" />

                </section>


                <section className="services-section">
                    <Container>
                        <Row className="item">
                            <Col lg="5" className="content">
                                <h3>{Object.translate(this.state.data, 'section[1].title', this.props.lang)}</h3>
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[1].text', this.props.lang) }}></div>
                            </Col>
                            <Col lg={{ size: 6, offset: 1 }} className="image">
                                <Image src={Object.get(this.state.data, 'section[1].image')} />
                            </Col>
                        </Row>
                        <Row className="item">
                            <Col lg="5" className="content">
                                <h3>{Object.translate(this.state.data, 'section[2].title', this.props.lang)}</h3>
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'section[2].text', this.props.lang) }}></div>
                            </Col>
                            <Col lg="6" className="image">
                                <Image src={Object.get(this.state.data, 'section[2].image')} />
                            </Col>
                        </Row>


                    </Container>
                </section>


                <Services lang={this.props.lang} services={this.props.services} />
                <sectin className="numbers-section">
                    <div>

                        <Container>
                            <Row>
                                <Col lg="4" className="item">
                                    <p>{Object.translate(this.state.data, 'section[3].block[0].value', this.props.lang)} {Object.translate(this.state.data, 'section[3].block[0].sign', this.props.lang)}</p>
                                    <p>{Object.translate(this.state.data, 'section[3].block[0].name', this.props.lang)}</p>
                                </Col>
                                <Col lg="4" className="item">
                                <p>{Object.translate(this.state.data, 'section[3].block[1].value', this.props.lang)} {Object.translate(this.state.data, 'section[3].block[1].sign', this.props.lang)}</p>
                                    <p>{Object.translate(this.state.data, 'section[3].block[1].name', this.props.lang)}</p>
                                </Col>

                                <Col lg="4" className="item">
                                <p>{Object.translate(this.state.data, 'section[3].block[2].value', this.props.lang)} {Object.translate(this.state.data, 'section[3].block[2].sign', this.props.lang)}</p>
                                    <p>{Object.translate(this.state.data, 'section[3].block[2].name', this.props.lang)}</p>
                                </Col>

                            </Row>
                        </Container>
                    </div>

                </sectin>
                <Technologies lang={this.props.lang}/>

                <Contact lang={this.props.lang}/>

            </div>
        );
    }
}

export default Page(AboutUsPage);