import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import Technologies from '../containers/sections/technologies';
import Contact from '../containers/sections/contact';
import Services from '../containers/sections/services';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import Image from '../components/image';

import rightChevron from '../assets/svg/right-chevron.svg';
import downChevron from '../assets/svg/down-chevron.svg';
import leftChevron from '../assets/svg/left-chevron.svg';
import linkIcon from '../assets/svg/link-icon.svg';




class ServicesDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        for(let i=0;i<this.props.loadData.length;i++){
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


    }


    render() {

        let data;

        for (let i = 0; i < this.props.services.length; i++) {
            if (Object.translate(this.props.services[i], 'alias', this.props.lang) == this.props[0].match.params.alias) {
                data = this.props.services[i];
                break;
            }
        }

        return (
            <div className="services-detail-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>

                            <Col lg="6" className="content">
                                <div className="mobile-image">
                                    <Image src={Object.get(data, 'detail.image')} />
                                </div>

                                <h1 dangerouslySetInnerHTML={{ __html: Object.translate(data, 'detail.title', this.props.lang) }}></h1>
                               {Object.translate(data, 'detail.buttonLink', this.props.lang) ? <Link disableTranslate lang={this.props.lang} to={Object.translate(data, 'detail.buttonLink', this.props.lang)}><button className="button">{Object.translate(data, 'detail.buttonText', this.props.lang)} <Isvg src={rightChevron} /></button></Link>:null}
                            </Col>
                            <button className="scroll-down"><Isvg src={downChevron} /></button>

                        </Row>
                        <div className="image">
                            <Image src={Object.get(data, 'detail.image')} />
                        </div>

                    </Container>
                </div>

                <section className="service-detail">

                    <Container>
                        <Row>
                            <Col lg="12" className="into-text" dangerouslySetInnerHTML={{ __html: Object.translate(data, 'detail.text', this.props.lang) }}>

                            </Col>
                            <Col lg="5" className="detail-content-left">
                                <h3>{Object.translate(data, 'section[0].title', this.props.lang)}</h3>
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(data, 'section[0].text', this.props.lang) }}></div>
                                <Link disableTranslate lang={this.props.lang} to={Object.translate(data, 'section[0].buttonLink', this.props.lang)}>{Object.translate(data, 'section[0].buttonText', this.props.lang)} <Isvg src={rightChevron} /></Link>
                            </Col>
                            <Col lg="7" className="detail-content-right">
                                <Image src={Object.get(data, 'section[0].image')} />
                            </Col>


                        </Row>
                    </Container>

                </section>

                <section className="service-promo">
                    <div className="overlay">
                        <Container>
                            <Row>
                                <Col lg="7" className="detail-content-right">
                                    <Image src={Object.get(data, 'section[1].image')} />
                                </Col>

                                <Col lg="5" className="detail-content-left">
                                    <h3>{Object.translate(data, 'section[1].title', this.props.lang)}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: Object.translate(data, 'section[1].text', this.props.lang) }}></div>
                                    <Link disableTranslate lang={this.props.lang} to={Object.translate(data, 'section[1].buttonLink', this.props.lang)}>{Object.translate(data, 'section[1].buttonText', this.props.lang)} <Isvg src={rightChevron} /></Link>
                                </Col>


                            </Row>
                        </Container>
                    </div>
                </section>


                {/* <section className="service-detail">

                    <Container>
                        <Row>
                            <Col lg="5" className="detail-content-left">
                            <h3>{Object.translate(data, 'section[2].title', this.props.lang)}</h3>
                               <div dangerouslySetInnerHTML={{__html: Object.translate(data, 'section[2].text', this.props.lang)}}></div>
                                <Link lang={this.props.lang} to={Object.translate(data, 'section[2].buttonLink', this.props.lang)}>{Object.translate(data, 'section[2].buttonText', this.props.lang)} <Isvg src={rightChevron} /></Link>
                            </Col>
                            <Col lg="7" className="detail-content-right">
                            <Image src={Object.get(data, 'section[2].image')} />
                            </Col>


                        </Row>
                    </Container>

                </section>
        */}

                <section className="projects-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <div className="section-top">
                                    <h2>{'Poslednji projekti'.translate(this.props.lang) }</h2>
                                    <h6>{'Predstavljamo Vam neke od naših novijih radova.'.translate(this.props.lang) }</h6>
                                    <Link lang={this.props.lang} to='/reference'><button className="button">{'Pogledajte sve projekte'.translate(this.props.lang) } <Isvg src={rightChevron} /></button></Link>
                                </div>

                                {
                                    this.state.promotedProjects.map((item, idx) => {
                                        if (idx % 2 == 0) {
                                            return (
                                                <div className="article">
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><Image src={Object.get(item, 'info.image')} /></Link>
                                                    <p className="category">{Object.translate(item, 'category.name', this.props.lang)}</p>
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{Object.translate(item, 'info.title', this.props.lang)}</h3></Link>
                                                    <p>{Object.translate(item, 'info.text', this.props.lang)}</p>
                                                </div>


                                            )
                                        }
                                    })
                                }



                            </Col>

                            <Col lg="6">
                                {
                                    this.state.promotedProjects.map((item, idx) => {
                                        if (idx % 2 != 0) {
                                            return (
                                                <div className="article">
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><Image src={Object.get(item, 'info.image')} /></Link>
                                                    <p className="category">{Object.translate(item, 'category.name', this.props.lang)}</p>
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{Object.translate(item, 'info.title', this.props.lang)}</h3></Link>
                                                    <p>{Object.translate(item, 'info.text', this.props.lang)}</p>
                                                </div>


                                            )
                                        }
                                    })
                                }

                            </Col>



                        </Row>
                    </Container>
                </section>



                <Contact lang={this.props.lang}/>

            </div>
        );
    }
}

export default Page(ServicesDetailPage);