import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import Technologies from '../containers/sections/technologies';
import Contact from '../containers/sections/contact';
import Services from '../containers/sections/services';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';



import Image from '../components/image';

import rightChevron from '../assets/svg/right-chevron.svg';
import downChevron from '../assets/svg/down-chevron.svg';
import leftChevron from '../assets/svg/left-chevron.svg';
import linkIcon from '../assets/svg/link-icon.svg';
import playIcon from '../assets/svg/play.svg';




class ProjectsDetailPage extends Component {
    constructor(props) {
        super(props);
        this.get = this.get.bind(this);


        this.state = {
            promotedProjects: [],
            ...props.initialData
        };
    }

    get() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for(let i=0;i<this.props.loadData.length;i++){
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


    }

    componentDidMount() {
        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.setState({
                playVideo: null
            })
            this.get();
        }
    }


    render() {

        return (
            <div className="projects-detail-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>

                            <Col lg="12" className="content">
                                <Link lang={this.props.lang} to='/reference'><button className="back-button"><Isvg src={leftChevron} /> {'Reference'.translate(this.props.lang)}</button></Link>

                                <h1>{Object.translate(this.state.data, 'detail.title', this.props.lang)}</h1>
                                <p>{Object.translate(this.state.data, 'detail.shortDescription', this.props.lang)}</p>
                            </Col>
                            <button className="scroll-down"><Isvg src={downChevron} /></button>

                        </Row>
                    </Container>
                </div>

                <section className="project-detail">
                    <div className="product-detail-main-image">
                        {this.state.playVideo ?
                            <video controls autoplay="autoplay">
                                <source src={Object.get(this.state.data, 'detail.video')} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            :
                            <>
                                <Image src={Object.get(this.state.data, 'detail.image[0]')} className={`main-image ${this.state.data && (this.state.data.category == '5e4dba5ec5972232c653f4ef' || this.state.data.category == '5e4dba74c5972232c653f4f1') ? 'x800-image' : ''}`} />
                                {Object.get(this.state.data, 'detail.video') ?
                                    <button className="play-button" onClick={() => this.setState({ playVideo: true })}>
                                        <Isvg src={playIcon} />
                                    </button>
                                    :
                                    null
                                }
                            </>
                        }

                    </div>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h3>{'O projektu'.translate(this.props.lang)}</h3>
                            </Col>
                            <Col lg="6">
                                <p className="technologies-title">{'Tehnologije'.translate(this.props.lang)}</p>
                                <p className="technologies">{Object.translate(this.state.data, 'technologies', this.props.lang)}</p>
                                {Object.translate(this.state.data, 'projectURL', this.props.lang) ? <a href={Object.translate(this.state.data, 'projectURL', this.props.lang)} target="_blank">{Object.translate(this.state.data, 'projectURL', this.props.lang)} <Isvg src={linkIcon} /></a> : null}
                            </Col>
                            <Col lg="6">
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'detail.text', this.props.lang) }}></div>
                            </Col>

                            <Col lg="12" className="images">
                                {
                                    this.state.data && this.state.data.detail && this.state.data.detail.image && this.state.data.detail.image.map((item, idx) => {
                                        if (idx > 0) {
                                            return (
                                                <Image key={idx} src={item} />

                                            )
                                        }
                                    })
                                }

                            </Col>


                        </Row>
                    </Container>

                </section>


                <section className="projects-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <div className="section-top">
                                    <h2>{'Izdvojeni projekti'.translate(this.props.lang)}</h2>
                                    <h6>{'Predstavljamo Vam neke od naših novijih radova.'.translate(this.props.lang)}</h6>
                                    <Link lang={this.props.lang} to='/reference'><button className="button">{'Pogledajte sve projekte'.translate(this.props.lang)} <Isvg src={rightChevron} /></button></Link>
                                </div>

                                {
                                    this.state.promotedProjects.map((item, idx) => {
                                        if (idx % 2 == 0) {
                                            return (
                                                <div className="article">
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><Image src={Object.get(item, 'info.image')} /></Link>
                                                    <p className="category">{Object.translate(item, 'category.name', this.props.lang)}</p>
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{Object.translate(item, 'info.title', this.props.lang)}</h3></Link>
                                                    <p>{Object.translate(item, 'info.text', this.props.lang)}</p>
                                                </div>


                                            )
                                        }
                                    })
                                }



                            </Col>

                            <Col lg="6">
                                {
                                    this.state.promotedProjects.map((item, idx) => {
                                        if (idx % 2 != 0) {
                                            return (
                                                <div className="article">
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><Image src={Object.get(item, 'info.image')} /></Link>
                                                    <p className="category">{Object.translate(item, 'category.name', this.props.lang)}</p>
                                                    <Link lang={this.props.lang} to={`/reference/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{Object.translate(item, 'info.title', this.props.lang)}</h3></Link>
                                                    <p>{Object.translate(item, 'info.text', this.props.lang)}</p>
                                                </div>


                                            )
                                        }
                                    })
                                }

                            </Col>



                        </Row>
                    </Container>
                </section>


                <Contact lang={this.props.lang}/>

            </div>
        );
    }
}

export default Page(ProjectsDetailPage);