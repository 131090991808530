import React, { Component } from 'react';
import Link from '../../components/link';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';



import serviceIcon from '../../assets/svg/service.svg';


class Services extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        return (
      
            <section className="services-list-section">
            <Container>
                <Row>
                    <Col lg="12">
                        <h2>{'Naše usluge'.translate(this.props.lang)}</h2>
                    </Col>
                    {
                        this.props.services && this.props.services.map((item, idx) => {
                            return (
                                <Col lg="4" sm="6" xs="12" className="article">
                                <Isvg src={item.info && item.info.icon} />
                                <Link lang={this.props.lang} to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{Object.translate(item, 'info.title', this.props.lang)}</h3></Link>
                                <div dangerouslySetInnerHTML={{__html: Object.translate(item, 'info.text', this.props.lang)}}></div>
                                <Link lang={this.props.lang} className="link" to={`/usluge/${Object.translate(item, 'alias', this.props.lang)}`}>{'Saznaj više'.translate(this.props.lang)}</Link>
                            </Col>
        
                            )
                        })
                    }

                </Row>
            </Container>
        </section>


               
        );
    }
}

export default Services;