import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import Technologies from '../containers/sections/technologies';
import Contact from '../containers/sections/contact';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import Image from '../components/image';


import leftChevron from '../assets/svg/left-chevron.svg';
import rightChevron from '../assets/svg/right-chevron.svg';
import moment from 'moment';
var striptags = require('striptags');

class BlogDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latestNews: [],
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }
    }



    render() {

        return (
            <div className="blog-detail-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>

                            <Col lg="12" className="content">
                                <Link lang={this.props.lang} to='/blog'> <button className="back-button"><Isvg src={leftChevron} /> {'Blog'.translate(this.props.lang)}</button></Link>

                                <h1 dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'title', this.props.lang) }}></h1>
                                <p>{Object.translate(this.state.data, 'category.name', this.props.lang)}<span>•</span> {this.state.data && moment.unix(this.state.data.timestamp).format('DD. MMMM YYYY.')} </p>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <section className="blog-detail">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <Image src={Object.get(this.state.data, 'image')} />
                                <div className="description" dangerouslySetInnerHTML={{ __html: Object.translate(this.state.data, 'content', this.props.lang) }}>

                                </div>

                                <div className="tags">
                                    <span>{'Tagovi'.translate(this.props.lang)}</span>

                                    <ul>
                                        {
                                            this.state.data && this.state.data.tags && Object.translate(this.state.data, 'tags', this.props.lang).map((item, idx) => {
                                                return (
                                                    <li key={idx}><Link lang={this.props.lang} to={`/blog/?tag=${item}`}>{item}</Link></li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="blog-section">
                    <Container>
                        <Row>
                            <Col lg="5">
                                <h2 dangerouslySetInnerHTML={{ __html: 'Najnovije<br />iz <span>bloga</span>'.translate(this.props.lang) }}  ></h2>
                                <Link lang={this.props.lang} to='/blog'><button className="button">Pogledaj kompletan blog <Isvg src={rightChevron} /></button></Link>
                            </Col>

                            <Col lg="7" className="articles">
                                {
                                    this.state.latestNews && this.state.latestNews.map((item, idx) => {
                                        return (

                                            <article>
                                                <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}> <Image src={item.image} /></Link>
                                                <div>
                                                    <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}><h3>{striptags(Object.translate(item, 'title', this.props.lang))}</h3></Link>
                                                    <Link lang={this.props.lang} to={`/blog/${Object.translate(item, 'alias', this.props.lang)}`}>{'Čitaj detaljnije'.translate(this.props.lang)}</Link>
                                                </div>
                                            </article>

                                        )
                                    })
                                }

                            </Col>
                        </Row>
                    </Container>
                </section>




                <Contact lang={this.props.lang}/>

            </div>
        );
    }
}

export default Page(BlogDetailPage);