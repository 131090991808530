import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/header';
import Footer from '../components/footer';

const lighModes = ['mode-normal', 'mode-dark', 'mode-light']

export const DefaultLayout = (Wrapped) => (props) => {
    return (
        <div className={"wrapper " + lighModes[props.lightMode]}>
            <Header {...props} />
            <Wrapped {...props} />
            <Footer {...props} />
            {!props.cookies && props.lang == 'en' ? <div className="cookies">
                <p>We use cookies to personalize and optimize user experience. For more information, visit our <Link to='/privacy-policy' >Privacy Policy</Link>.</p>
                <button onClick={() => { props.allowCookies() }}>I AGREE</button>
            </div> :
                null
            }

        </div>
    );
};

export default DefaultLayout;